import React from 'react'
import { DownloadImageButton, PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Grid, Typography } from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { formatMailingAddress, formatZip4Field } from '../../../utils/common/commonUtils';
import styled from '@emotion/styled';

interface Props {
    apnDetail: any
    handleParcelDetailClick: Function
    isStarter: Boolean
    isReference: Boolean
    searchProximity: any
    policyNumber: string
}

const SectionDataBox = styled(Box)`
        padding: 15px;
        box-shadow: none;
`;

function APNList({ apnDetail, handleParcelDetailClick, isStarter, isReference, searchProximity, policyNumber }: Props) {
    if (searchProximity && !isNaN(parseFloat(searchProximity))) {
        searchProximity = parseFloat(searchProximity).toFixed(2);
    }
    return (
        <>

            <SectionDataBox>
                <Grid container rowSpacing={2}>
                    {!isStarter &&
                        <Grid item xs={0.3} md={0.4} lg={0.3}>

                        </Grid>
                    }
                    <Grid item xs={0.5} md={1} lg={1}><PropertyHeader>Policy APN</PropertyHeader></Grid>
                    <Grid item xs={2.5} md={3} lg={3}><Typography data-testid={policyNumber + "_APN"}>{apnDetail?.policy_apn}</Typography></Grid>
                    <Grid item xs={0.8} md={1.2} lg={1}><PropertyHeader>Address</PropertyHeader></Grid>
                    <Grid item xs={5} md={4.5} lg={3.5} data-testid={policyNumber + "_Address"}>{formatMailingAddress(apnDetail?.Address, apnDetail?.city, apnDetail?.state, formatZip4Field(apnDetail?.zip, apnDetail?.zip4), "")}</Grid>
                    {isStarter && isReference &&
                        <>
                            <Grid item xs={0.5} md={1} lg={1.3}><PropertyHeader>Search Proximity</PropertyHeader></Grid>
                            <Grid item xs={2.5} md={3} lg={2}><Typography data-testid={policyNumber + "_Proximity"}>{searchProximity ? searchProximity + ' Miles' : ''}</Typography></Grid>
                        </>
                    }
                    {
                        apnDetail?.spectr_propert_id &&
                        apnDetail?.fips &&
                        !isStarter &&
                        <Grid item xs={1.9} md={2.7} lg={3.2} sx={{ display: 'flex', justifyContent: 'end' }}>

                            <DownloadImageButton data-testid={policyNumber + "_ParcelDetail"} onClick={() => handleParcelDetailClick(apnDetail?.spectr_propert_id, apnDetail?.fips)} sx={{ color: "#0075AA !important", maxHeight: "36px", backgroundColor: "white" }} variant="outlined" startIcon={<FormatAlignLeftIcon />}>
                                Parcel Detail
                            </DownloadImageButton>

                        </Grid>
                    }
                </Grid>
                <Grid container rowSpacing={2} sx={{ paddingTop: "25px !important" }}>
                    {!isStarter &&
                        <Grid item xs={0.3} md={0.4} lg={0.3}>

                        </Grid>
                    }
                    <Grid item xs={2} md={1} lg={1.25} ><PropertyHeader>Assessor APN</PropertyHeader></Grid>
                    <Grid item xs={2.5} md={3} lg={3}><Typography data-testid={policyNumber + "_APN"}>{!apnDetail?.spectr_propert_id || !apnDetail?.fips ? "N/A" : apnDetail?.assessor_apn}</Typography></Grid>
                </Grid>
            </SectionDataBox >


        </>
    )
}

export default APNList
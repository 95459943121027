import { PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import styles from './policySearchResult.module.css';
import styled from '@emotion/styled';
import { getLoggingEventName } from '../../../utils/common/commonUtils';
import { useNavigate } from 'react-router-dom';
import { setTabToState } from '../../../components/ui/propertyTable/tabIdentifierSlice';
import { useAppDispatch } from '../../../app/hooks';
import logAPI from '../../../common/Api/logAPI';
import { LoggingEvent, ParcelDetail_Log_Event } from '../../../utils/constants/constants';
import APNList from './APNList';
import APNListLastLine from './APNListLastLine';

interface Props {
    apnDetail: any,
    policyInfo: any,
    isPropertyDetailsOpen: boolean,
    key?: number,
    tabName: string,
    subTabName?: string,
    isStarter?: Boolean,
    isDirect?: Boolean,
    isReference?: Boolean,
    loggingEventName: string
}

const SectionDataBox = styled(Box)`
        padding: 15px;
        box-shadow: none;
`;

const APNResult = ({ apnDetail, policyInfo, isPropertyDetailsOpen, tabName, isStarter, isDirect, isReference, loggingEventName, subTabName = "" }: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let policyNumber = policyInfo?.policy_no;

    const handleParcelDetailClick = (propertyId: any, fips: any) => {
        if (!propertyId && !fips)
            return;

        logAPI({
            Event: ParcelDetail_Log_Event, //getLoggingEventName(tabName) + LoggingEvent.ParcelView, //PolicySearch_Loggin_Event.Policy_Search_Parcel_Event,
            FileNumber: policyInfo?.file_number,
            PolicyNumber: policyInfo?.policy_no,
            FullStreetAddress: apnDetail?.Address,
            City: apnDetail?.city,
            State: apnDetail?.state,
            UnitNumber: apnDetail?.unit,
            Zip: apnDetail?.zip,
            Zip4: apnDetail?.zip4,
            APN: apnDetail?.APN,
            Fips: apnDetail?.fips,
            SearchOptionUsed: getLoggingEventName(loggingEventName)
        });
        dispatch(setTabToState({ tabName: tabName, subTabName: subTabName }));
        let query = "/parcel-detail/" + propertyId + "?fips=" + fips;
        navigate(query);
    }
    return (
        <>
            {isStarter ?
                <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                    <APNList policyNumber={policyInfo?.policy_no} isReference={isReference ?? false} searchProximity={policyInfo?.search_proximity} apnDetail={apnDetail} isStarter={isStarter ?? false} handleParcelDetailClick={handleParcelDetailClick} />
                </Collapse>
                :
                <APNList policyNumber={policyInfo?.policy_no} isReference={isReference ?? false} searchProximity={policyInfo?.search_proximity} apnDetail={apnDetail} isStarter={isStarter ?? false} handleParcelDetailClick={handleParcelDetailClick} />
            }
            <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                <SectionDataBox>
                    <Grid container rowSpacing={3.5}>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.6} lg={1.7}><PropertyHeader>Policy Subdivision</PropertyHeader></Grid>
                        <Grid item xs={2} md={4.2} lg={3.3}><Typography data-testid={policyNumber + "_PolicySubdivision"}>{apnDetail?.policy_division}</Typography> </Grid>
                        <Grid item xs={1} md={1.6} lg={2.3}>{apnDetail?.spectr_propert_id && apnDetail?.fips && <PropertyHeader>Assessment Subdivision</PropertyHeader>}</Grid>
                        <Grid item xs={2} md={4.2} lg={4.4}><Typography data-testid={policyNumber + "_AssessmentSubdivision"}>{apnDetail?.spectr_propert_id && apnDetail?.fips ? (apnDetail?.assessment_division ? apnDetail?.assessment_division : "N/A"): ""}</Typography></Grid>
                        {/* {!isStarter &&
                            <Grid item xs={0} md={0.4} lg={0} sx={{ display: 'none' }} className={styles.max960space}>

                            </Grid>
                        } */}
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={0.5} md={1.3} lg={1}><PropertyHeader>Lot</PropertyHeader></Grid>
                        <Grid item xs={1.3} md={2} lg={1.5}><Typography data-testid={policyNumber + "_Lot"}>{apnDetail?.lot}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.3} lg={1.1}><PropertyHeader>Block</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.6} lg={1.5}><Typography data-testid={policyNumber + "_Block"}>{apnDetail?.block}</Typography> </Grid>
                        {/* <Hidden mdDown lgDown> */}
                        <Grid item xs={1} md={1.6} lg={1.3}><PropertyHeader>Book/Volume</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.8} lg={1.5}><Typography data-testid={policyNumber + "_BookVolume"} sx={{ wordBreak: 'break-word' }}>{apnDetail?.book_volume}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.2} lg={1.5}><PropertyHeader>Page</PropertyHeader></Grid>
                        <Grid item xs={1} md={0.8} lg={2.3}><Typography data-testid={policyNumber + "_Page"}>{apnDetail?.page}</Typography> </Grid>
                        {/* </Hidden> */}
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Section</PropertyHeader></Grid>
                        <Grid item xs={2} md={3.9} lg={2.7}><Typography data-testid={policyNumber + "_Section"}>{apnDetail?.section}</Typography> </Grid>
                        <Grid item xs={1} md={1.3} lg={1.3}><PropertyHeader>Township</PropertyHeader></Grid>
                        <Grid item xs={0.8} md={2} lg={0.5}><Typography data-testid={policyNumber + "_Township"}>{apnDetail?.township}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.5} lg={1.2}><PropertyHeader>Range</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.4} lg={4.7}><Typography data-testid={policyNumber + "_Range"}>{apnDetail?.range}</Typography> </Grid>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Quarter 1</PropertyHeader></Grid>
                        <Grid item xs={2} md={3.9} lg={2.7}><Typography data-testid={policyNumber + "_Quarter1"}>{apnDetail?.quarter1}</Typography> </Grid>
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Quarter 2</PropertyHeader></Grid>
                        <Grid item xs={0.8} md={1.8} lg={0.5}><Typography data-testid={policyNumber + "_Quarter2"}>{apnDetail?.quarter2}</Typography> </Grid>
                        <Grid item xs={1} md={1.5} lg={1.2}><PropertyHeader>Quarter 3</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.4} lg={4.7}><Typography data-testid={policyNumber + "_Quarter3"}>{apnDetail?.quarter3}</Typography> </Grid>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1.5} md={3} lg={2.5}><PropertyHeader>
                            {isDirect ? "Direct Starter" : isReference ? "Reference Starter" : ""} Full Legal Description:
                        </PropertyHeader></Grid>
                        <Grid item xs={10.2} md={8.6} lg={9.2} data-testid={policyNumber + "_FullLegalDescription"}><Typography>{apnDetail?.full_legal_description}</Typography> </Grid>
                    </Grid>
                </SectionDataBox>
            </Collapse>
            {isStarter ?
                <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                    <APNListLastLine />
                </Collapse>
                :
                <APNListLastLine />
            }

            {/* <SectionDataBox className={styles.topPadding}>
                <Grid container>
                    <Grid item xs={0.3}>
                    </Grid>
                    <Grid item xs={11.7} className={styles.lastLineBorder}><PropertyHeader></PropertyHeader></Grid>
                </Grid>
            </SectionDataBox> */}
        </>
    )
}

export default APNResult